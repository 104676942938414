<template>
    <div class="userCenter" :style="userCenter">
        <van-cell-group class="bgNo userCenterInfo">
            <van-cell center :label="'工作单位：' + certification.company">
                <template #icon>
                    <van-image class="thumbnailImg mr10" :src="user.wxHeadimgurl" />
                </template>
                <template #title>
                    <span class="custom-title">{{ user.wxNickname }}</span>
                    <van-tag size="mini" class="userTag" :style="tagBg">Lv.1</van-tag>
                </template>
            </van-cell>
        </van-cell-group>
        <div class="userCenterLink">
            <van-cell-group class="userLinkList">
            <van-cell center title="我的认证信息" to="/doctor/my/certification">
                <template #icon
                    ><van-image class="linkThumbnailImg mr10" :src="LinkIcon01"
                /></template>
            </van-cell>
            <van-cell center title="我的邀请码" to="/doctor/my/inviteCode">
                <template #icon
                    ><van-image class="linkThumbnailImg mr10" :src="LinkIcon02"
                /></template>
            </van-cell>
        </van-cell-group>
        <div class="logout">
            <van-button class="mt40" :style="logoutButton" block  @click="logout">退出</van-button>
        </div>
        </div>
    </div>
</template>

<script>
import { getUser } from '@/services/user';
import { toast } from '@/util';
import { getCertification } from '@/services/doctor';

export default {
    // name: "index"
    data() {
        return {
            user: {},
            certification: {},
            userImg: [require('@/assets/images/userImg.png')],
            userSetting: require('@/assets/images/shezhi.png'),
            userTag: require('@/assets/images/crown.png'),
            LinkIcon01: require('@/assets/images/certification.png'),
            LinkIcon02: require('@/assets/images/userLinkIcon-02.png'),
            userCenter: {
                backgroundImage: 'url(' + require('@/assets/images/myBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            },
            tagBg: {
                backgroundImage: 'url(' + require('@/assets/images/tabBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            },
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            logoutButton: {
                color:'#4c4c4c',
                boxSizing:'border-box',
                borderRadius: '8px',
                fontSize:'14px',
                background:'#ffff',
            },
        };
    },
    created() {
        this.getUser();
        this.getCertification();
    },
    methods: {
        async getUser() {
            const { code, message, data } = await getUser();
            if (!code) {
                toast(message);
            }
            this.user = data;
        },
        async getCertification() {
            const { code, message, data } = await getCertification();
            if (!code) {
                toast(message);
            }
            this.certification = data;
        },

        logout() {
            localStorage.clear();
            this.$router.push('/passport/wxAuthLogin');
        },
    },
};
</script>

<style scoped>
.userCenterInfo .van-cell {
    background: none;
    padding: 25px;
}
.thumbnailImg {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
}
.custom-title {
    font-size: 14px;
    color: #282a2d;
}
.userTag {
    padding: 2px 0px;
    color: #fff;
    margin-left: 5px;
    font-size: 10px;
    width: 45px;
    display: inline-block;
    height: 15px;
    line-height: 17px;
    text-indent: 19px;
    position: relative;
    top: -2px;
}
.userSetting {
    width: 30px;
    height: 30px;
}

.userLinkList .van-cell {
    border-radius: 8px;
    margin-bottom: 5px;
}
.linkThumbnailImg {
    width: 30px;
    height: 30px;
}
.userLinkList .van-cell__title {
    font-size: 13px;
    color: #4c4c4c;
}
.userCenter {
    height: 100%;
}
.userCenterLink {
    background: #f6f6f8;
    padding:5px;
    height: calc(100% - 160px);
    overflow-y: auto;
}
.userCenterLink .logout{
    /* padding: 0 5px; */
}
.van-button::before{
    display: none;
}
</style>
